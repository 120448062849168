import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {SearchComponent} from "../../search/search/search.component";
import {MatDialog} from "@angular/material/dialog";
import {NavigationTransmissionService} from "../../raintree/transmission/navigation-transmission.service";
import {NavigationCraft} from "../interface/navigation-craft";
import {MoldedContentSegmantCategory} from "../desktop-navigation/interface/molding/molded-content-segment-category";
import {NavigationMaterialOption} from "../interface/navigation-material-option";
import {NavigationColorOption} from "../interface/navigation-color-option";
import {NavigationPatternOption} from "../interface/navigation-pattern-option";
import {NavigationCraftOption} from "../interface/navigation-craft-option";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {WishlistService} from "../../wishlist/wishlist.service";
import {NavigationStory} from "../interface/navigation-story";
import {NavigationStoryOption} from "../interface/navigation-story-option";
import {GetAndSetCartDataInLocalstorageService} from "../../cart/service/set-cart-data-in-localstorage.service";
import {CartTransmissionService} from "../../raintree/transmission/cart/cart-transmission.service";
import {PrepareGetCartService} from "../../cart/service/prepare-get-cart.service";
import {CheckoutPrepareCartResponseService} from "../../checkout/service/checkout-prepare-cart-response.service";
import {CartCalculationService} from "../../cart/service/cart-calculation.service";
import {JWTService} from "../../authentication/jwt.service";
import {RequestMapperService} from "../../request-mapper.service";
import {GetCart} from "../../cart/interface/cart-get";
import {Cart} from "../../cart/interface/cart";
import {RaintreeResponse} from "../../raintree/interface/raintree-response";
import {ToastMessageService} from "../../services/toast-message.service";
import {isPlatformBrowser} from "@angular/common";
import {Customer} from "../../raintree/interface/customer-profile/customer";
import {RightSideMenu} from "../desktop-navigation/interface/right--side-menu";
import {GlobalService} from "../../services/global.service";
import {AuthService} from "@auth0/auth0-angular";
import {GenerateNavigationUrlService} from "../service/generate-navigation-url.service";

@UntilDestroy()
@Component({
	selector: 'fb-new-navigation',
	templateUrl: './new-navigation.component.html',
	styleUrls: ['./new-navigation.component.scss']
})
export class NewNavigationComponent implements OnInit {

	public showNotificationBar: boolean = true;
	public isMobileMenuOpen: boolean = false;
	public isBrowser: boolean = false;
	public isLoggedIn = false;
	public isCartOpen: boolean = false;
	public cartCount: number = 0;
	public fetchedCartData: Cart[] = [];
	public tenantName: string = "";

	public wishlistCount: number = 0;
	public navigationCraft: { isLoading: boolean, craft: NavigationCraft[] } = {isLoading: true, craft: []};
	public navigationAccessories: { isLoading: boolean, accessories: NavigationCraft[] } = {
		isLoading: true,
		accessories: []
	};
	public navigationHome: { isLoading: boolean, home: NavigationCraft[] } = {isLoading: true, home: []};
	public navigationApparel: { isLoading: boolean, apparel: NavigationCraft[] } = {isLoading: true, apparel: []};
	public selectedAccessory: NavigationCraftOption = {} as NavigationCraftOption;
	public selectedHomeware: NavigationCraftOption = {} as NavigationCraftOption;
	public selectedApparel: NavigationCraftOption = {} as NavigationCraftOption;
	public navigationMaterial: { isLoading: boolean, materials: NavigationMaterialOption[] } = {
		isLoading: true,
		materials: []
	};
	public navigationColor: { isLoading: boolean, colors: NavigationColorOption[] } = {isLoading: true, colors: []};
	public navigationPattern: { isLoading: boolean, patterns: NavigationPatternOption[] } = {
		isLoading: true,
		patterns: []
	};

	public navigationStoryCrafts: { isLoading: boolean, crafts: NavigationStory[] } = {isLoading: true, crafts: []};
	public selectedCraftsStory: NavigationStoryOption = {} as NavigationStoryOption;

	public navigationStoryClusters: { isLoading: boolean, clusters: NavigationStory[] } = {
		isLoading: true,
		clusters: []
	};
	public selectedClusterStory: NavigationStoryOption = {} as NavigationStoryOption;

	public navigationStoryCollaborations: { isLoading: boolean, collaborations: NavigationStory[] } = {
		isLoading: true,
		collaborations: []
	};
	public selectedCollaborationStory: NavigationStoryOption = {} as NavigationStoryOption;


	constructor(
		private _dialog: MatDialog,
		private _api: NavigationTransmissionService,
		private _getSetCartInLocal: GetAndSetCartDataInLocalstorageService,
		private _cartTransmission: CartTransmissionService,
		private _wishlist: WishlistService,
		private _prepareCartResponse: PrepareGetCartService,
		private _prepareCheckoutCartResponse: CheckoutPrepareCartResponseService,
		private _cartCalculation: CartCalculationService,
		private _jwt: JWTService,
		private _toast: ToastMessageService,
		private _globalService: GlobalService,
		public generateUrl: GenerateNavigationUrlService,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	ngOnInit() {

		this._globalService.customerProfile
			.pipe(untilDestroyed(this))
			.subscribe((res: Customer): void => {
				this.isLoggedIn = true;
				this.tenantName = res.tenant.name.split(" ")[0];

				if(this.isBrowser) {
					localStorage.removeItem("cartData");
				}

				this.getCartData(true);

			});

		this._getSetCartInLocal.openCartModal
			.pipe(untilDestroyed(this))
			.subscribe((isOpen: boolean): void => {

				this.isCartOpen = isOpen;
				if (this._jwt.hasValidJWT()) {
					if (isOpen) {
						this.getCartData();
					}
				}
			});

		setTimeout((): void => {
			this._getSetCartInLocal.getCartFromLocal();
		}, 0);

		this._getSetCartInLocal.cartResponse
			.pipe(untilDestroyed(this))
			.subscribe((data: Cart[]) => {
				// debugger
				this.fetchedCartData = data;
				this.cartCount = this.fetchedCartData ? this.fetchedCartData.length : 0;
			});

		this._api.getNavigationFabricCraftResult(
			() => {
				this.navigationCraft.isLoading = true;
			},
			() => {
			},
			(response: NavigationCraft[]) => {
				this.navigationCraft.craft = response;
				this.navigationCraft.isLoading = false;
			},
			() => {
			},
			() => {
			}
		);

		this._api.getNavigationFinishResult(
			'accessories',
			() => {
				this.navigationAccessories.isLoading = true;
			},
			() => {
			},
			(response: NavigationCraft[]) => {
				this.navigationAccessories.accessories = response;
				this.selectedAccessory = response[0].optionList[0];
				this.navigationAccessories.isLoading = false;
			},
			() => {
			},
			() => {
			}
		);

		this._api.getNavigationFinishResult(
			'home',
			() => {
				this.navigationHome.isLoading = true;
			},
			() => {
			},
			(response: NavigationCraft[]) => {
				this.navigationHome.home = response;
				this.selectedHomeware = response[0].optionList[0];
				this.navigationHome.isLoading = false;
			},
			() => {
			},
			() => {
			}
		);

		this._api.getNavigationFinishResult(
			'apparel',
			() => {
				this.navigationApparel.isLoading = true;
			},
			() => {
			},
			(response: NavigationCraft[]) => {
				this.navigationApparel.apparel = response;
				this.selectedApparel = response[0].optionList[0];
				this.navigationApparel.isLoading = false;
			},
			() => {
			},
			() => {
			}
		);

		this._api.getNavigationStoryResult(
			'crafts',
			() => {
				this.navigationStoryCrafts.isLoading = true;
			},
			() => {
			},
			(response: NavigationStory[]) => {
				this.navigationStoryCrafts.crafts = response;
				this.selectedCraftsStory = this.navigationStoryCrafts.crafts[0].optionList[0];
				this.navigationStoryCrafts.isLoading = false;
			},
			() => {
			},
			() => {
			}
		);

		this._api.getNavigationStoryResult(
			'clusters',
			() => {
				this.navigationStoryClusters.isLoading = true;
			},
			() => {
			},
			(response: NavigationStory[]) => {
				this.navigationStoryClusters.clusters = response;
				this.selectedClusterStory = this.navigationStoryClusters.clusters[0].optionList[0];
				this.navigationStoryClusters.isLoading = false;
			},
			() => {
			},
			() => {
			}
		);

		this._api.getNavigationStoryResult(
			'collaborations',
			() => {
				this.navigationStoryCollaborations.isLoading = true;
			},
			() => {
			},
			(response: NavigationStory[]) => {
				this.navigationStoryCollaborations.collaborations = response;
				this.selectedCollaborationStory = this.navigationStoryCollaborations.collaborations[0].optionList[0];
				this.navigationStoryCollaborations.isLoading = false;
			},
			() => {
			},
			() => {
			}
		);

		this._api.getNavigationFabricMaterialResult(
			() => {
				this.navigationMaterial.isLoading = true;
			},
			() => {
			},
			(response: NavigationMaterialOption[]) => {
				this.navigationMaterial.materials = response;
				this.navigationMaterial.isLoading = false;
			},
			() => {
			},
			() => {
			}
		);

		this._api.getNavigationFabricPatternResult(
			() => {
				this.navigationPattern.isLoading = true;
			},
			() => {
			},
			(response: NavigationPatternOption[]) => {
				this.navigationPattern.patterns = response;
				this.navigationPattern.isLoading = false;
			},
			() => {
			},
			() => {
			}
		);

		this._api.getNavigationFabricColorResult(
			() => {
				this.navigationColor.isLoading = true;
			},
			() => {
			},
			(response: NavigationColorOption[]) => {
				this.navigationColor.colors = response;
				this.navigationColor.isLoading = false;
			},
			() => {
			},
			() => {
			}
		);

		this.wishlistCount = this._wishlist.getWishlistCSV().split(',')[0] === '' ? 0 :
			this._wishlist.getWishlistCSV().split(',').length;

		this._wishlist.wishlistItems
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				this.wishlistCount = this._wishlist.getWishlistCSV().split(',')[0] === '' ? 0 :
					this._wishlist.getWishlistCSV().split(',').length;
			});
	}

	public onEnter($event: MouseEvent, dropdownBackground: HTMLDivElement): void {
		const trigger: HTMLElement = $event.target as HTMLElement;
		const dropdown: HTMLElement | null = trigger.querySelector('.fb-s-nav-dropdown');
		const navbar: HTMLElement | null = document.querySelector('.fb-s-navigation');
		if (!dropdown || !navbar) {
			return;
		}
		trigger.classList.add('trigger-enter');
		setTimeout(() => trigger.classList.contains('trigger-enter') && trigger.classList.add('trigger-enter-active'), 150);
		dropdownBackground.classList.add('open');
		const dropdownCoords: DOMRect = dropdown.getBoundingClientRect();
		const navCoords: DOMRect = navbar.getBoundingClientRect();

		const viewportWidth = window.innerWidth;

		let leftPosition = dropdownCoords.left - navCoords.left;

		if (dropdownCoords.right > viewportWidth) {
			leftPosition -= (dropdownCoords.right - (viewportWidth - 30));
			dropdown.style.setProperty('left', `-${(dropdownCoords.right - (viewportWidth - 10))}px`);
		}
		const coords = {
			height: dropdownCoords.height,
			width: dropdownCoords.width,
			top: dropdownCoords.top - navCoords.top,
			left: leftPosition,
		};

		dropdownBackground.style.setProperty('width', coords.width + 'px');
		dropdownBackground.style.setProperty('height', coords.height + 'px');
		dropdownBackground.style.setProperty('transform', `translate(${coords.left}px, ${coords.top}px`);

		const arrow: HTMLElement | null = dropdownBackground.querySelector('.arrow');
		if (arrow) {
			const triggerCoords = trigger.getBoundingClientRect();
			let leftPositionArrow = dropdownCoords.left;
			if (dropdownCoords.right > viewportWidth) {
				leftPositionArrow -= (dropdownCoords.right - viewportWidth);
			}
			const arrowLeft = triggerCoords.left - leftPositionArrow + (triggerCoords.width / 2) - (arrow.offsetWidth / 2);

			arrow.style.setProperty('left', `${arrowLeft}px`);
		}

	}

	public onLeave($event: MouseEvent, dropdownBackground: HTMLDivElement): void {
		const trigger: HTMLElement = $event.target as HTMLElement;
		trigger.classList.remove('trigger-enter', 'trigger-enter-active');
		dropdownBackground.classList.remove('open');
	}

	public openSearchBar = () => {
		this._dialog.open(SearchComponent, {
			maxWidth: '900px',
			width: '90vw',
			maxHeight: '85vh',
			position: {
				top: '70px'
			}
		});
	}


	public setSelectedAccessory(subCategory: NavigationCraftOption): void {
		if (subCategory.subCategoryFeaturedImage)
			this.selectedAccessory = subCategory;
	}

	public setSelectedHomeware(subCategory: NavigationCraftOption): void {
		if (subCategory.subCategoryFeaturedImage)
			this.selectedHomeware = subCategory;
	}

	public setSelectedApparel(subCategory: NavigationCraftOption): void {
		if (subCategory.subCategoryFeaturedImage)
			this.selectedApparel = subCategory;
	}

	public setSelectedStoryCrafts(subCategory: NavigationStoryOption): void {
		if (subCategory.bannerImage)
			this.selectedCraftsStory = subCategory;
	}

	public setSelectedStoryClusters(subCategory: NavigationStoryOption): void {
		if (subCategory.bannerImage)
			this.selectedClusterStory = subCategory;
	}

	public setSelectedStoryCollaboration(subCategory: NavigationStoryOption): void {
		if (subCategory.bannerImage)
			this.selectedCollaborationStory = subCategory;
	}

	public cartLengthUpdate(evt: number) {
		evt = this.cartCount
	}

	public addCartData(data: Cart): void {

		// console.log(data)
		this._cartTransmission.AddCartItem(
			RequestMapperService.ADD_CART_ITEM,
			data,
			() => {
			},
			() => {
			},
			(response: RaintreeResponse) => {
				this._toast.success(response.message, response.message, 'bottom-center');
				// localStorage.removeItem("cartData");
			},
			(error: string) => {
				// console.log("TODO add toaster: custom message: after login cart a add hoche na")
			},
			() => {
			}
		)
	}

	public getCartData(onload?: boolean): void {
		// debugger
		this._cartTransmission.getCartItem(
			RequestMapperService.GET_CART_ITEM,
			() => {
			},
			() => {
			},
			(response: GetCart[]) => {
				if (response.length > 0) {
					setTimeout(() => {
						this._getSetCartInLocal.cartResponse.next(response)
						this.fetchedCartData = this._prepareCartResponse.prepareCartList(response);
						this.fetchedCartData = this._prepareCheckoutCartResponse.prepareCheckoutList(response);
						this.cartCount = response.length
						this.setCartDataInLocal(this.fetchedCartData);

						if (onload) {
							this._cartCalculation.selectedFinishForCartCalc = this._cartCalculation.zeroElementFinish
							this._getSetCartInLocal.openCartModal.next(false); // DNR: this is for finish component reload
						}

					}, 0);
				}
			},
			() => {
				this.afterLoginAddCartDataFromLocal();
			},
			() => {
			}
		)
	}

	public afterLoginAddCartDataFromLocal(): void {
		if (this.isLoggedIn && this.isBrowser && localStorage.getItem("cartData")) {
			let localCartResponse: Cart[] = [...JSON.parse(localStorage.getItem("cartData")!)];
			localCartResponse.forEach((cartData: Cart) => {
				this.addCartData(cartData)
			})
		}
	}

	public setCartDataInLocal(data: Cart[]) {
		if (this.isBrowser) {
			localStorage.removeItem("cartData");
			localStorage.setItem("cartData", JSON.stringify(data))
		}

	}


	public refreshCartList(): void {
		this.getCartData();
	}

	public openCart(): void {
		if (this.cartCount === 0) {
			this._toast.error("Cart is empty", "Your Cart is empty. Add item to your cart and try again", 'bottom-center');
			return;
		}
		this.isCartOpen = true;
		if (this._jwt.hasValidJWT()) {
			this.getCartData();

		}
	}

	public storeUrl(): void {
		if (this.isBrowser) {
			localStorage.setItem("storeUrl", window.location.href);
		}
	}
}
