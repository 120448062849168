<header class="desk_nav w-full">
	<fb-notification-bar (closeNotificatonNav)="closeNotification()" *ngIf="isOpen"></fb-notification-bar>
	<div class="fb-s-navigation-container w-full flex justify-center items-center">
		<fb-new-navigation class="w-full flex justify-center items-center"></fb-new-navigation>
	</div>

</header>

<!--<div class="mobile_nav">-->
<!--	<fb-mobile-navigation *ngIf="navShow"-->
<!--						  [dataMenuList]="navigationData" [dataStaticLeftMenu]="dataStaticLeftMenu"-->
<!--						  [dataStaticRightMenu]="dataStaticRightMenu"></fb-mobile-navigation>-->
<!--</div>-->
