<section class="fb-home-blog-new w-full flex justify-center items-center py-10">
	<div *ngIf="!loading" class="container flex flex-col justify-between items-center px-4">
		<div class="fb-blog-gallery lg:mt-5">
			<div class="w-full mb-5">
				<h2 class="text-xl md:text-6xl">All</h2>
				<h1 class="text-2xl md:text-7xl text-[#b7a98f] font-medium">
					{{ storyContentType }}
				</h1>
				<a routerLink="/story"
				   target="_blank"
				   class="text-base md:text-xl py-2 fb_animate_icon_button">
					<i class="fb_animate"><b ></b><span></span></i>
					Discover More
				</a>
			</div>
			<owl-carousel-o [options]="customOptions">
				<ng-container *ngFor="let story of storyPreview">
				<ng-template carouselSlide>
						<div class="fb-blog-container">
							<div class="fb-blog-content">
								<h3 class="fb-blog-title">{{story.title}}</h3>
								<p class="fb-blog-description" [innerHTML]="story.description | truncketHtml:150">
								</p>
								<a routerLink="{{'/story-details/'+story.slug+'/'+story.id}}"
								   target="_blank"
								   class="text-base py-2 fb_story_button ">
									<button>Learn More</button>
									<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"/></svg>
								</a>
							</div>

							<img class="fb-blog-image"
								 [src]="story.bannerImageDesktop"
								 [alt]="story.title"/>
						</div>
				</ng-template>
				</ng-container>
			</owl-carousel-o>
		</div>
		<div class="fb-blog-gallery mt-5">
			<div class="fb-product-container grid-product-2">
				<ng-container *ngFor="let product of products">
					<a [routerLink]="blogView.getProductLink(product)"
					   class="fb-fp-card flex flex-col justify-center items-center relative">
						<img class="fb-fp-image" [src]="product.heroImage" [alt]="product.name" />
						<div
							class="w-[90%] max-w-[300px] flex justify-between items-center fb-fp-view  px-2 py-1.5 absolute bottom-5">
							<p class="text-white text-xs sm:text-sm font-semibold">
								{{product.name | truncketHtml: 15}}
							</p>
						</div>
					</a>
				</ng-container>
			</div>
		</div>
	</div>
</section>
