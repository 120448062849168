import {Component, OnInit} from '@angular/core';
import {FeaturedProductsTransmissionService} from "../../raintree/transmission/featured-products-transmission.service";
import {IFeaturedProduct} from "../interface/featured-product";
import {OwlOptions} from "ngx-owl-carousel-o";
import {OwlMultiSliderConfigService} from "../service/owl-multi-slider-config.service";

@Component({
	selector: 'fb-home-featured-products',
	templateUrl: './home-featured-products.component.html',
	styleUrls: ['./home-featured-products.component.scss']
})
export class HomeFeaturedProductsComponent implements OnInit {

	protected selectedTabIndex: number = 0;
	protected selectedCategory: string = 'fabrics';
	protected featuredProductsList: IFeaturedProduct[] = [];

	protected customOptions: OwlOptions = OwlMultiSliderConfigService.owlOptions;

	constructor(
		private _api: FeaturedProductsTransmissionService
	) {
	}

	ngOnInit(): void {
		this.resolveFeaturedProducts();
	}

	private resolveFeaturedProducts(): void {
		this._api.getFeaturedProducts(
			this.selectedCategory,
			() => {
			},
			(response: IFeaturedProduct[]) => {
			},
			(response: IFeaturedProduct[]) => {
				this.featuredProductsList = response;
			},
			(error: string) => {
			},
			() => {
			}
		);
	}

	private prepareUrl(param: string): string {
		if(param.includes('-'))
			return param.toLowerCase().replace(/\s+/g, '');
		return param.toLowerCase().replace(/\s+/g, '-');
	}

	protected onTabChange(category: string, tabIndex: number): void {
		this.selectedCategory = category;
		this.selectedTabIndex = tabIndex;
		this.resolveFeaturedProducts();
	}

	protected onRedirection(featuredProduct: IFeaturedProduct): string {
		if (this.selectedCategory === 'fabrics') {
			return `/products/fabric?${this.prepareUrl(featuredProduct.segmentCategoryName)}=${this.prepareUrl(featuredProduct.subCategoryName)}`;
		} else {
			return `/products/finished?${this.prepareUrl(featuredProduct.segmentCategoryName)}=${this.prepareUrl(featuredProduct.subCategoryName)}`;
		}
	}

}
