import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {RequestMapperService} from "../../request-mapper.service";
import {JWTService} from "../../authentication/jwt.service";
import {ToastMessageService} from "../../services/toast-message.service";
import {GlobalService} from "../../services/global.service";
import {AuthService} from "@auth0/auth0-angular";
import {isPlatformBrowser} from "@angular/common";

@Component({
	selector: 'fb-navigation-customer-dropdown',
	animations: [
		trigger('enterAnimation', [
			transition(':enter', [
				style({transform: 'translateX(0)', opacity: 0}),
				animate(
					'200ms',
					style({transform: 'translateX(0)', opacity: 1})
				),
			]),
			transition(':leave', [
				style({transform: 'translateX(0)', opacity: 1}),
				animate(
					'200ms',
					style({transform: 'translateX(0)', opacity: 0})
				),
			]),
		]),
	],
	templateUrl: './navigation-customer-dropdown.component.html',
	styleUrls: ['./navigation-customer-dropdown.component.scss']
})
export class NavigationCustomerDropdownComponent {


	@Input()
	public tenantName: string = "";
	public isBrowser: boolean = false;
	public customerShow: boolean = false;

	constructor(
		private _jwt: JWTService,
		private _globalService: GlobalService,
		private _authService: AuthService,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	public logout(): void {
		this._jwt.destroySession();
		this.customerShow = false;

		if (this.isBrowser) {
			this._authService.logout({
				logoutParams: {
					returnTo: this.isBrowser ? window.location.origin : ''
				}
			}).subscribe(() => {
				location.href = RequestMapperService.BASE_RELATIVE_URL;
				if (this.isBrowser) {
					localStorage.removeItem("cartData");
				}

			});
		}
	}

	protected readonly transition = transition;
}
