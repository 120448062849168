<div *ngIf="selectedForexOption !== ApCurrency.UNKNOWN" class="select_trigger_cont">
	<button class="bg-[#fffcf7] hover:bg-white hover:shadow-md rounded-3xl border-2 border-[#8E7862] text-[#8E7862] py-1 px-2 hover:border-[#6c5b48]
						transition flex items-center justify-center"
			(click)="forexShow = !forexShow">
		<span class="sort_text uppercase">{{selectedForexOption}}</span>
		<span *ngIf="!forexShow" class="material-symbols-outlined">
				arrow_drop_down
		</span>

		<span *ngIf="forexShow" class="material-symbols-outlined">
				arrow_drop_up
		</span>

	</button>

	<div class="select" [@enterAnimation] *ngIf="forexShow">
		<div *ngFor="let option of currencyList"
			 class="option uppercase {{selectedForexOption===option.name.toLowerCase()?'active_sort_option':''}}"
			 (click)="setForex(option.name.toLowerCase())">
			{{option.name}}
		</div>
	</div>
</div>
