import { Injectable } from '@angular/core';
import {TransmissionService} from "./transmission.service";
import {HttpClient} from "@angular/common/http";
import {HttpErrorHandlerService} from "../http-error-handler.service";
import {JWTService} from "../../authentication/jwt.service";
import {PasteboxService} from "../../services/pastebox.service";
import {ConstantService} from "../../services/constant.service";
import {RequestMapperService} from "../../request-mapper.service";
import {NavigationCraft} from "../../navigation/interface/navigation-craft";
import {NavigationCraftPayload} from "../interface/navigation-craft-payload";
import {NavigationMaterialOption} from "../../navigation/interface/navigation-material-option";
import {NavigationPatternOption} from "../../navigation/interface/navigation-pattern-option";
import {NavigationColorOption} from "../../navigation/interface/navigation-color-option";
import {NavigationStory} from "../../navigation/interface/navigation-story";

@Injectable({
	providedIn: 'root'
})
export class NavigationTransmissionService extends TransmissionService {

	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
		_pastebox: PasteboxService
	) {
		super(
			_http,
			_httpErrorHandler,
			_jwt,
			_pastebox
		);
	}

	public getNavigationFabricCraftResult(
		onPreExecute: () => void,
		onPostExecute: (response: NavigationCraft[]) => void,
		onSuccess: (response: NavigationCraft[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void{
		this.executeGetPayload<NavigationCraftPayload,NavigationCraft[]>(
			RequestMapperService.GET_NAVIGATION_FABRIC_CRAFT,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.ENTITY_ATTRIBUTE_KEY,
			false
		);
	}

	public getNavigationFinishResult(
		category: string,
		onPreExecute: () => void,
		onPostExecute: (response: NavigationCraft[]) => void,
		onSuccess: (response: NavigationCraft[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void{
		this.executeGetPayload<NavigationCraftPayload,NavigationCraft[]>(
			RequestMapperService.GET_NAVIGATION_FINISHED + category,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.ENTITY_ATTRIBUTE_KEY,
			false
		);
	}

	public getNavigationStoryResult(
		category: string,
		onPreExecute: () => void,
		onPostExecute: (response: NavigationStory[]) => void,
		onSuccess: (response: NavigationStory[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void{
		this.executeGetPayload<NavigationCraftPayload,NavigationStory[]>(
			RequestMapperService.GET_NAVIGATION_STORY + category,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.ENTITY_ATTRIBUTE_KEY,
			false
		);
	}


	public getNavigationFabricMaterialResult(
		onPreExecute: () => void,
		onPostExecute: (response: NavigationMaterialOption[]) => void,
		onSuccess: (response: NavigationMaterialOption[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void{
		this.executeGetPayload<NavigationCraftPayload,NavigationMaterialOption[]>(
			RequestMapperService.GET_NAVIGATION_FABRIC_MATERIAL_LIST,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.ENTITY_ATTRIBUTE_KEY,
			false
		);
	}

	public getNavigationFabricPatternResult(
		onPreExecute: () => void,
		onPostExecute: (response: NavigationPatternOption[]) => void,
		onSuccess: (response: NavigationPatternOption[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void{
		this.executeGetPayload<NavigationCraftPayload,NavigationPatternOption[]>(
			RequestMapperService.GET_NAVIGATION_FABRIC_PATTERN_LIST,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.ENTITY_ATTRIBUTE_KEY,
			false
		);
	}

	public getNavigationFabricColorResult(
		onPreExecute: () => void,
		onPostExecute: (response: NavigationColorOption[]) => void,
		onSuccess: (response: NavigationColorOption[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void{
		this.executeGetPayload<NavigationCraftPayload,NavigationColorOption[]>(
			RequestMapperService.GET_NAVIGATION_FABRIC_COLOR_LIST,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.ENTITY_ATTRIBUTE_KEY,
			false
		);
	}
}
