import {Injectable} from '@angular/core';
import {TransmissionService} from "./transmission.service";
import {HttpClient} from "@angular/common/http";
import {HttpErrorHandlerService} from "../http-error-handler.service";
import {JWTService} from "../../authentication/jwt.service";
import {PasteboxService} from "../../services/pastebox.service";
import {IFeaturedProduct} from "../../home/interface/featured-product";
import {IFeaturedProductResponse} from "../interface/featured-product-response";
import {RequestMapperService} from "../../request-mapper.service";
import {ConstantService} from "../../services/constant.service";

@Injectable({
	providedIn: 'root'
})
export class FeaturedProductsTransmissionService extends TransmissionService {

	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
		_pastebox: PasteboxService
	) {
		super(
			_http,
			_httpErrorHandler,
			_jwt,
			_pastebox
		);
	}

	public getFeaturedProducts(
		category: string,
		onPreExecute: () => void,
		onPostExecute: (response: IFeaturedProduct[]) => void,
		onSuccess: (response: IFeaturedProduct[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {
		this.executeGetPayload<IFeaturedProductResponse, IFeaturedProduct[]>(
			RequestMapperService.GET_FEATURED_PRODUCTS.replace('{category}', category),
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.FEATURED_PRODUCT_ATTRIBUTE_KEY,
			false
		)
	}
}
