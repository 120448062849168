import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ConstantService {

	public static readonly MOBILE_NUMBER_PATTERN: RegExp = /^[0-9]{6,20}$/;
	public static readonly ZIP_CODE_PATTERN: RegExp = /^[0-9]{1,20}$/;
	public static readonly COULD_NOT_CONNECT_TO_SERVER_ERROR: string = 'could not connect to our servers';
	public static readonly WORKING: string = 'working';
	public static readonly CONFIRM: string = 'Please confirm your choice.';
	public static readonly YOUTUBE_VIDEO_REGEX: RegExp = /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/shorts\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^\/&]{10,12})/;
	public static readonly VIMEO_VIDEO_REGEX: RegExp = /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:video\/)?(\d+)/;

	/* JWT CONSTANTS */

	public static readonly AUTHORIZATION_HEADER: string = 'Authorization';
	public static readonly CONTENT_TYPE_HEADER: string = 'Content-Type';
	public static readonly AUTHORIZATION_SCHEME: string = 'Bearer ';
	public static readonly EXPIRED_TOKEN: string = 'expired-token';
	public static readonly DOMAIN_NOT_ALLOWED: string = 'domain-not-allowed';
	public static readonly NO_JWT_TOKEN: string = 'no-jwt-token';

	/* ATTRIBUTE KEY CONSTANTS */

	public static readonly PROFILE_ATTRIBUTE_KEY: string = 'profile';
	public static readonly BLOG_CONTENT_ATTRIBUTE_KEY: string = 'blogContent';
	public static readonly BLOG_CONTENT_LIST_ATTRIBUTE_KEY: string = 'blogContentList';
	public static readonly USER_ATTRIBUTE_KEY: string = 'users';
	public static readonly AUTHORITY_ATTRIBUTE_KEY: string = 'authority';

	public static readonly ENTITY_ATTRIBUTE_KEY = "entity";
	public static readonly PRODUCT_ATTRIBUTE_KEY = "product";
	public static readonly PRODUCTS_ATTRIBUTE_KEY: string = "products";
	public static readonly RELATED_PRODUCTS_ATTRIBUTE_KEY: string = "relatedProductsList";
	public static readonly ORDER_FEEDBACK_ATTRIBUTE_KEY: string = "orderFeedback";
	public static readonly PRODUCT_LISTING_ATTRIBUTE_KEY: string = "productPreviewList";
	public static readonly COLOR_LIST_ATTRIBUTE_KEY: string = "colorList";
	public static readonly MATERIAL_LIST_ATTRIBUTE_KEY: string = "materialList";
	public static readonly PATTERN_LIST_ATTRIBUTE_KEY: string = "patternList";
	public static readonly FILTER_SEO_ATTRIBUTE_KEY: string = "filterSeo";
	public static readonly SPECTACLE_ATTRIBUTE_KEY = "spectacle";
	public static readonly MESSAGE_ATTRIBUTE_KEY = 'message';
	public static readonly REVIEW_ATTRIBUTE_KEY: string = 'review';
	public static readonly REVIEW_LIST_ATTRIBUTE_KEY: string = 'reviewList';
	public static readonly REVIEW_STATS_ATTRIBUTE_KEY: string = 'reviewStats';
	public static readonly FEATURED_PRODUCT_ATTRIBUTE_KEY: string = 'featuredSubCategories';

	private constructor() {
	}
}
