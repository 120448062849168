import { Injectable } from '@angular/core';
import {NavigationCraft} from "../interface/navigation-craft";

@Injectable({
  providedIn: 'root'
})
export class GenerateNavigationUrlService {

	constructor() {
	}

	private _prepareUrl(param: string): string {
		if (param.includes('-'))
			return param.toLowerCase().replace(/\s+/g, '');
		return param.toLowerCase().replace(/\s+/g, '-');
	}

	public generateRedirectionLink(url: string, name: string, value: string): string {
		return url + '?' + name + '=' + this._prepareUrl(value);
	}

	public generateCategoryRedirectionLink(url: string, segment: NavigationCraft): string {
		const segments: string[] = segment.optionList.map((sub) => this._prepareUrl(sub.subCategoryName));
		return url + '?' + this._prepareUrl(segment.segmentCategoryName) + '=' + segments.join();
	}

	public generateSegmentRedirectionLink(url: string, segment: string, subCategory: string): string {
		return url + '?' + this._prepareUrl(segment) + '=' + this._prepareUrl(subCategory);
	}
}
