import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TopStaticNavComponent} from './top-static-nav/top-static-nav.component';
import {NavigationComponent} from './navigation/navigation.component';
import {DesktopNavigationModule} from './desktop-navigation/desktop-navigation.module';
import {MobileNavigationModule} from "./mobile-navigation/mobile-navigation.module";
import { NewNavigationComponent } from './new-navigation/new-navigation.component';
import {RouterLink} from "@angular/router";
import {ComponentsLibraryModule} from "../components-library/components-library.module";
import {LazyLoadImageModule} from "ng-lazyload-image";
import { NavigationForexDropdownComponent } from './navigation-forex-dropdown/navigation-forex-dropdown.component';
import {CartModule} from "../cart/cart.module";
import { NavigationCustomerDropdownComponent } from './navigation-customer-dropdown/navigation-customer-dropdown.component';
import { NavigationMobile2Component } from './navigation-mobile-2/navigation-mobile-2.component';


@NgModule({
	declarations: [
		TopStaticNavComponent,
		NavigationComponent,
  NewNavigationComponent,
  NavigationForexDropdownComponent,
  NavigationCustomerDropdownComponent,
  NavigationMobile2Component,
	],
    imports: [
        CommonModule,
        DesktopNavigationModule,
        MobileNavigationModule,
        RouterLink,
        ComponentsLibraryModule,
        LazyLoadImageModule,
        CartModule
    ],
	exports: [
		TopStaticNavComponent,
		NavigationComponent,
		NewNavigationComponent,
	]
})
export class NavigationModule {
}
