import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {Cart} from '../interface/cart';
import {RaintreeResponse} from 'src/app/raintree/interface/raintree-response';
import {CartTransmissionService} from 'src/app/raintree/transmission/cart/cart-transmission.service';
import {RequestMapperService} from 'src/app/request-mapper.service';
import {ToastMessageService} from 'src/app/services/toast-message.service';
import {GetCart} from '../interface/cart-get';
import {PrepareGetCartService} from '../service/prepare-get-cart.service';
import {GetAndSetCartDataInLocalstorageService} from '../service/set-cart-data-in-localstorage.service';
import {CheckoutPrepareCartResponseService} from 'src/app/checkout/service/checkout-prepare-cart-response.service';
import {CustomSize} from '../interface/custom-size';
import {CheckoutFabricVolumeDiscountPriceService} from 'src/app/checkout/service/checkout-fabric-volume-discount-price.service';
import {ApCurrency} from "../../pipe/enum/ap-currency";
import {WishlistService} from "../../wishlist/wishlist.service";
import {BloomsightService} from "../../bloomsight.service";

@Component({
	selector: 'fb-cart-item',
	templateUrl: './cart-item.component.html',
	styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnChanges {

	public quantity = 1;
	public incDic = 1;
	public totalQuantity = 100000;//:TODO add quantity calculation- this is gonna dynamic
	@Input() cartItem: Cart = {} as Cart;
	@Output() quantityUpdate = new EventEmitter<any>();
	@Output() emptyCartDisplay = new EventEmitter<boolean>();
	@Input() index = 0;
	@Input()
	public preferredCurrency: ApCurrency = ApCurrency.UNKNOWN;

	constructor(
		private _cartTransmission: CartTransmissionService,
		public _toast: ToastMessageService,
		private _prepareCartResponse: PrepareGetCartService,
		private _etSetCartData: GetAndSetCartDataInLocalstorageService,
		private _prepareCheckoutCartResponse: CheckoutPrepareCartResponseService,
		private _fabricVolumeDiscountPriceService: CheckoutFabricVolumeDiscountPriceService,
		private _wishlist: WishlistService,
		private _bloomsight: BloomsightService
	) {

	}

	ngOnChanges() {
		this._checkCustomSizeProfile();
		this._checkInStockQtyWithCartQty();
		this._deleteNegativePricedItem();
	}

	private _checkInStockQtyWithCartQty(): void {
		if (this.cartItem.orderType != 'PRE_ORDER' && this.cartItem.productGroup != 'swatch' && this.cartItem.quantity >= this.cartItem.itemInStock!) {
			if(this.cartItem.itemInStock !== undefined) {
				if(this.cartItem.itemInStock > 0 ) {
					this.quantity = this.cartItem.itemInStock;
					this.cartItem.quantity = this.quantity;
					this.quantityUpdate.emit({item: this.cartItem, index: this.index});
					this.updateCartData(this.cartItem);
				} else {
					this.deleteCartItem(this.cartItem.id);
				}
			}
		}
	}

	private _checkCustomSizeProfile(): void {
		if (this.cartItem.customSize) {
			if (this.cartItem.fabricProductPreview) {
				if (!this.cartItem.fabricProductPreview.product.customSizeProfile) {
					this.cartItem.customSize = {};
					this.updateCartData(this.cartItem);
				}
			} else if (this.cartItem.finishedProductPreview) {
				if (!this.cartItem.finishedProductPreview.product.customSizeProfile) {
					this.cartItem.customSize = {};
					this.updateCartData(this.cartItem);
				}
			}
		}
	}

	private _deleteNegativePricedItem(): void {
		if (this.cartItem.price! < 1) {
			this.deleteCartItem(this.cartItem.id, true);
		}
	}

	public decreaseQTY(): void {

		if (this.cartItem.productGroup != 'swatch' && this.quantity <= this.cartItem.minQuantity!) {
			return
		}

		if (this.quantity <= this.incDic) {
			return
		} else {
			this.quantity = this.quantity - this.incDic;
		}
		if(this.quantity <  1) {
			this.quantity = 1;
		}
		this.cartItem.quantity = this.quantity;
		// this._prepareFabricVolumeDiscountPrice(this.index);
		this.quantityUpdate.emit({item: this.cartItem, index: this.index})
		if (this.cartItem.productGroup == 'swatch') {
			delete this.cartItem.selectedFinishList
		}
		this.updateCartData(this.cartItem);
		// this.orderQuantityExceeded = false;
	}

	public increaseQTY(): void {
		debugger


		if (this.cartItem.orderType != 'PRE_ORDER' && this.cartItem.productGroup != 'swatch' && this.quantity >= this.cartItem.itemInStock!) {
			this._toast.warning('You Can Order Upto ' + this.cartItem.itemInStock + ' Items', 'maximum quantity reached');
			return
		}


		if (this.quantity >= this.totalQuantity) {
			// this.orderQuantityExceeded = true;
			return
		} else {
			this.quantity = this.quantity + this.incDic;
			// this.orderQuantityExceeded = false;
		}
		this.cartItem.quantity = this.quantity;
		// this._prepareFabricVolumeDiscountPrice(this.index);
		this.quantityUpdate.emit({item: this.cartItem, index: this.index});
		// debugger
		if (this.cartItem.productGroup == 'swatch') {
			delete this.cartItem.selectedFinishList
		}
		this.updateCartData(this.cartItem);
		// this.sendUpdate()
	}

	public changeQTYFromInput(evt: any): void {
		// this.orderQuantityExceeded = false;
			this.quantity = +evt.target.value
		if(+evt.target.value < 1) {
			evt.target.value = '1';
			this.quantity = 1;
		}

		if (this.cartItem.orderType != 'PRE_ORDER' && this.cartItem.productGroup != 'swatch' && this.quantity >= this.cartItem.itemInStock!) {
			this._toast.warning('You Can Order Upto ' + this.cartItem.itemInStock + ' Items', 'maximum quantity reached');
			this.quantity = this.cartItem.itemInStock!;
			evt.target.value = this.cartItem.itemInStock! + '';
			if(+evt.target.value < 1) {
				evt.target.value = '1';
				this.quantity = 1;
			}
			this.cartItem.quantity = this.quantity;
			this.quantityUpdate.emit({item: this.cartItem, index: this.index});
			this.updateCartData(this.cartItem);
			return;
		}

		if (this.cartItem.productGroup != 'swatch' && this.quantity <= this.cartItem.minQuantity!) {
			// this._toast.warning('You Can not Order bellow '+this.cartItem.minQuantity+' Items','Minimum quantity reached');
			this.quantity = this.cartItem.minQuantity!;
			evt.target.value = this.cartItem.minQuantity! + '';
			this.cartItem.quantity = this.quantity;
			if(+evt.target.value < 1) {
				evt.target.value = '1';
				this.quantity = 1;
			}
			this.quantityUpdate.emit({item: this.cartItem, index: this.index});
			this.updateCartData(this.cartItem);
			return;
		}


		if (+(evt.target.value) < 0) {
			evt.target.value = '0';
		}

		if (+(evt.target.value) > this.totalQuantity) {
			evt.target.value = '0';
			// this.orderQuantityExceeded = true;
		}

		this.quantity = +(evt.target.value);
		this.cartItem.quantity = this.quantity;
		this.quantityUpdate.emit({item: this.cartItem, index: this.index});

		this.updateCartData(this.cartItem);
	}

	public deleteCartItem(id: number | undefined, track: boolean = false): void {
		if (track && this.cartItem.sku)
			this._bloomsight.trackDataEvent(
				'65f0342afe3931befe4c463b',
				{'sku': this.cartItem.sku},
				''
			);
		this.deleteCartData(id!)
	}

	public ngOnInit(): void {
		// debugger
		// console.log(this.cartItem);

		if (this.cartItem.fabricProductId) this.incDic = 0.5;
		else this.incDic = 1;

		if (this.cartItem.productGroup === 'swatch') this.incDic = 1;

		this.quantity = this.cartItem.quantity;
	}


	public updateCartData(data: Cart) {
		// debugger
		// console.log(data);
		if (!data.fabricProductId) delete data.fabricProductId;
		if (!data.finishedProductId) delete data.finishedProductId;
		if (!data.fabricName) delete data.fabricName;
		if (!data.selectedFabricProfileItemId) delete data.selectedFabricProfileItemId;
		if (!data.selectedSizeOption) delete data.selectedSizeOption;
		if (!data.selectedFinishProfileItemId) delete data.selectedFinishProfileItemId;
		if (data.productGroup === 'swatch') data.orderType = 'IN_STOCK';

		// console.log(data);


		this._cartTransmission.updateCartItem(
			RequestMapperService.UPDATE_CART_ITEM,
			data,
			() => {
			},
			() => {
			},
			(response: RaintreeResponse) => {
			},
			(error: string) => {
				// console.log("TODO add toaster: custom message: after login cart a add hoche na")
			},
			() => {
			}
		)
	}

	public deleteCartData(id: number) {

		this._cartTransmission.deleteCartItem(
			RequestMapperService.DELETE_CART_ITEM + id,
			{} as Cart,
			() => {
			},
			() => {
			},
			(response: RaintreeResponse) => {
				// this._toast.success(response.message, response.message, 'bottom-center');
				localStorage.removeItem("cartData");
				this.getCartData();
			},
			(error: string) => {
				// console.log("TODO add toaster: custom message: after login cart a add hoche na")
			},
			() => {
			}
		)
	}

	public getCartData() {

		this._cartTransmission.getCartItem(
			RequestMapperService.GET_CART_ITEM,
			() => {
			},
			() => {
			},
			(response: GetCart[]) => {
				if (response.length > 0) {

					let data: Cart[] = this._prepareCheckoutCartResponse.prepareCheckoutList(response);
					this.setCartDataInLocal(data);
					this._etSetCartData.cartResponse.next(data);

				} else {
					// debugger
					this.emptyCartDisplay.emit(true);
					this._etSetCartData.cartResponse.next([]);
					this._prepareCartResponse.prepareCartList([]);
				}
			},
			(): void => {
			},
			(): void => {
			}
		)
	}

	public setCartDataInLocal(data: Cart[]) {
		localStorage.removeItem("cartData");
		localStorage.setItem("cartData", JSON.stringify(data));
		this._etSetCartData.cartResponse.next(data)
	}

	public prepareCustomSizeLabel(customSizeData: CustomSize): string {

		let listOfLabel: string[] = [];
		let label: string = '';

		for (const [key, value] of Object.entries(customSizeData)) {
			listOfLabel.push(`${key}: ${value}`);
		}

		label = listOfLabel.join();

		return label
	}

	// private _prepareFabricVolumeDiscountPrice( index: number): void {

	// 	if(this.cartItem.selectedFabricProfileItemId) {

	// 		let priceFabricVD = this._fabricVolumeDiscountPriceService.calculateFabricVolumeDiscountPrice(this.cartItem);

	// 		if(priceFabricVD){
	// 			this.cartItem.price = priceFabricVD;
	// 		}
	// 		else this.cartItem.price = this.cartItem.copyPrice;
	// 	}
	// }


	public moveToWishlist() {
		if (this.cartItem.sku) {
			this._bloomsight.trackDataEvent(
				'65f0347bfe3931befe4c4679',
				{sku: this.cartItem.sku},
				''
			);
			if (this._wishlist.isInWishlist(this.cartItem.sku)) {
				this.deleteCartItem(this.cartItem.id);
			} else {
				this._wishlist.toggle(
					this.cartItem.productName ? this.cartItem.productName : '',
					this.cartItem.sku,
					false
				);
				this.deleteCartItem(this.cartItem.id);
			}
			this._toast.success("Moved to Wishlist", this.cartItem.productName + ' has been moved to wishlist');
		}
	}
}
