import {Component, EventEmitter, Inject, Input, OnChanges, Output, PLATFORM_ID, SimpleChanges} from '@angular/core';
import {NavigationCraft} from "../interface/navigation-craft";
import {GenerateNavigationUrlService} from "../service/generate-navigation-url.service";
import {NavigationMaterialOption} from "../interface/navigation-material-option";
import {NavigationColorOption} from "../interface/navigation-color-option";
import {NavigationPatternOption} from "../interface/navigation-pattern-option";
import {NavigationStory} from "../interface/navigation-story";
import {NavigationStoryOption} from "../interface/navigation-story-option";
import {RequestMapperService} from "../../request-mapper.service";
import {JWTService} from "../../authentication/jwt.service";
import {AuthService} from "@auth0/auth0-angular";
import {isPlatformBrowser} from "@angular/common";

@Component({
	selector: 'fb-navigation-mobile-2',
	templateUrl: './navigation-mobile-2.component.html',
	styleUrls: ['./navigation-mobile-2.component.scss']
})
export class NavigationMobile2Component implements OnChanges {

	@Input()
	public isMobileMenuOpen: boolean = false;
	@Input()
	public tenantName: string = "";
	@Input()
	public isLoggedIn: boolean = false;
	@Input()
	public navigationCraft: { isLoading: boolean, craft: NavigationCraft[] } = {isLoading: true, craft: []};

	@Input()
	public navigationMaterial: { isLoading: boolean, materials: NavigationMaterialOption[] } = {
		isLoading: true,
		materials: []
	};
	@Input()
	public navigationColor: { isLoading: boolean, colors: NavigationColorOption[] } = {isLoading: true, colors: []};
	@Input()
	public navigationPattern: { isLoading: boolean, patterns: NavigationPatternOption[] } = {
		isLoading: true,
		patterns: []
	};

	@Input()
	public navigationAccessories: { isLoading: boolean, accessories: NavigationCraft[] } = {
		isLoading: true,
		accessories: []
	};
	@Input()
	public navigationHome: { isLoading: boolean, home: NavigationCraft[] } = {isLoading: true, home: []};
	@Input()
	public navigationApparel: { isLoading: boolean, apparel: NavigationCraft[] } = {isLoading: true, apparel: []};
	@Input()
	public navigationStoryCrafts: { isLoading: boolean, crafts: NavigationStory[] } = {isLoading: true, crafts: []};
	@Input()
	public navigationStoryClusters: { isLoading: boolean, clusters: NavigationStory[] } = {
		isLoading: true,
		clusters: []
	};
	@Input()
	public navigationStoryCollaborations: { isLoading: boolean, collaborations: NavigationStory[] } = {
		isLoading: true,
		collaborations: []
	};

	@Output()
	public EisMobileMenuOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

	public selectedMenuOption: string = "";
	public selectedSubMenuOption: string = "craft";
	public isBrowser: boolean = false;

	constructor(
		public generateUrl: GenerateNavigationUrlService,
		private _jwt: JWTService,
		private _authService: AuthService,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	ngOnChanges() {
	}

	public triggerAccordion(str: string): void {
		if (this.selectedMenuOption === str) {
			this.selectedMenuOption = '';
		}
		else {
			this.selectedMenuOption = str;
		}
	}

	public triggerSubAccordion(str: string): void {
		if (this.selectedSubMenuOption === str) {
			this.selectedSubMenuOption = '';
		}
		else {
			this.selectedSubMenuOption = str;
		}
	}

	public closeMenu(): void {
		this.isMobileMenuOpen = false;
		this.EisMobileMenuOpen.next(false);
	}

	public logout(): void {
		this._jwt.destroySession();
		this.isLoggedIn = false;

		if (this.isBrowser) {
			this._authService.logout({
				logoutParams: {
					returnTo: this.isBrowser ? window.location.origin : ''
				}
			}).subscribe(() => {
				location.href = RequestMapperService.BASE_RELATIVE_URL;
				if (this.isBrowser) {
					localStorage.removeItem("cartData");
				}

			});
		}
	}
}
