import {Component, ElementRef, Input} from '@angular/core';
import {PrimaryNavigation} from '../interface/primary-navigation';
// import { NavigationList } from '../model/navigation-list';
// import { StaticNavList } from '../desktop-navigation/model/static-nav-list';
import {LeftSideMenu} from '../desktop-navigation/interface/left-side-menu';
import {RightSideMenu} from '../desktop-navigation/interface/right--side-menu';

@Component({
	selector: 'fb-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
	@Input() dataStaticLeftMenu: LeftSideMenu[] = [];
	@Input() dataStaticRightMenu: RightSideMenu[] = [];
	@Input()
	public navShow: boolean = true;

	public isOpen: boolean = true;
	constructor(private elementRefNav: ElementRef) {

	}
	@Input() navigationData: PrimaryNavigation[] = [];


	public closeNotification(): void {
		this.isOpen = false;
	}
}
