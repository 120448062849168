import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {HomeRoutingModule} from './home-routing.module';
import {HomeComponent} from './home/home.component';
import {ComponentsLibraryModule} from '../components-library/components-library.module';
import {SingleCarouselModule} from '../components-library/single-carousel/single-carousel.module';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {HomeBannerComponent} from './home-banner/home-banner.component';
import {HomepageInstagramPostComponent} from './homepage-instagram-post/homepage-instagram-post.component';
import {PipeModule} from "../pipe/pipe.module";
import {HomeAllStoriesComponent} from './home-all-stories/home-all-stories.component';
import {MatIconModule} from "@angular/material/icon";
import {HomeThirdPartyComponent} from './home-third-party/home-third-party.component';
import {HomeBlogViewComponent} from './home-blog-view/home-blog-view.component';
import {HomeBlogView2Component} from './home-blog-view-2/home-blog-view-2.component';
import {HomeBlogView3Component} from './home-blog-view-3/home-blog-view-3.component';
import {HomeBlogViewMobileComponent} from './home-blog-view-mobile/home-blog-view-mobile.component';
import {HomeCaraouselComponent} from './home-caraousel/home-caraousel.component';
import {LazyLoadImageModule} from "ng-lazyload-image";
import {HomeReviewCarouselComponent} from './home-review-caraousel/home-review-carousel.component';
import {HomeFeaturedProductsComponent} from './home-featured-products/home-featured-products.component';


@NgModule({
	declarations: [
		HomeComponent,
		HomeBannerComponent,
		HomepageInstagramPostComponent,
		HomeAllStoriesComponent,
		HomeThirdPartyComponent,
		HomeBlogViewComponent,
		HomeBlogView2Component,
		HomeBlogView3Component,
		HomeBlogViewMobileComponent,
		HomeCaraouselComponent,
		HomeReviewCarouselComponent,
		HomeFeaturedProductsComponent
	],
	imports: [
		CommonModule,
		HomeRoutingModule,
		ComponentsLibraryModule,
		SingleCarouselModule,
		CarouselModule,
		PipeModule,
		MatIconModule,
		LazyLoadImageModule
	],
	exports: [
		HomeComponent
	],
})
export class HomeModule {
}
