<div class="select_trigger_cont">
	<button (mouseenter)="customerShow = true"
			(click)="customerShow = !customerShow"
			class="transition flex items-center justify-center gap-1">
		<span *ngIf="!customerShow" class="material-symbols-outlined">
					person
		</span>
		<span *ngIf="customerShow" class="material-symbols-outlined">
					close
		</span>
		<span class="text-xs">{{tenantName}}</span>
	</button>

	<div class="select" [@enterAnimation] *ngIf="customerShow" (mouseleave)="customerShow = false">
		<a routerLink="/profile" class="option " (click)="customerShow = false">
			Dashboard
		</a>

		<a routerLink="/profile/order" class="option " (click)="customerShow = false">
			Orders
		</a>
		<a routerLink="/profile/account" class="option " (click)="customerShow = false">
			Account
		</a>
		<a routerLink="/contact" (click)="customerShow = false" class="option">
			Contact Us
		</a>
		<div (click)="logout()" class="option flex gap-2 items-center">
			Logout
			<span class="material-symbols-outlined">
					logout
			</span>
		</div>


	</div>
</div>
