import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {LeftSideMenu} from './navigation/desktop-navigation/interface/left-side-menu';
import {RightSideMenu} from './navigation/desktop-navigation/interface/right--side-menu';
import {StaticNavList} from './navigation/desktop-navigation/model/static-nav-list';
import {PrimaryNavigation} from './navigation/interface/primary-navigation';
import {NavigationList} from './navigation/model/navigation-list';
import {RequestMapperService} from './request-mapper.service';
import {Event, NavigationEnd, NavigationError, Router} from '@angular/router';
import {CustomerProfileTransmissionService} from './raintree/transmission/customer-profile-transmission/customer-profile-transmission.service';
import {Customer} from './raintree/interface/customer-profile/customer';
import {GlobalService} from './services/global.service';
import {JWTService} from './authentication/jwt.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {SettingsDataService} from './services/settings-data.service';
import {Discount} from './checkout/interface/discount';
import {DiscountTransmissionService} from './raintree/transmission/discount-transmisssion/discount-transmission.service';
import {AutoDiscountCalculationService} from './checkout/service/auto-discount-calculation.service';
import {ForexListTransmissionService} from './raintree/transmission/forex-list-transmission/forex-list-transmission.service';
import {CartCalculationService} from './cart/service/cart-calculation.service';
import {PriceCalculationService} from './product-details/service/price-calculation.service';
import {GtagAPIService} from "@bloomscorp/ngx-gtag";
import {BrowserAPIService, LocalStorageService} from "@bloomscorp/ngbase";
import {StoreForexService} from "./navigation/service/store-forex.service";
import {CurrencyResolverService} from "./currency-resolver.service";
import {isEmptyString} from "bmx-pastebox";
import {UpdateForexInProfileService} from "./navigation/service/update-forex-in-profile.service";
import {Forex} from "./navigation/desktop-navigation/interface/forex";
import {ForexTransmissionService} from "./raintree/transmission/forex-transmision/forex-transmission.service";
import {WishlistService} from "./wishlist/wishlist.service";
import {BloomsightService} from "./bloomsight.service";

@UntilDestroy()
@Component({
	selector: 'fb-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

	public hideNavForPages: string[] = [
		// RequestMapperService.NOT_FOUND_URL
	]
	public isHomePage = true;
	public currentRoute = '';
	public showCookieBanner: boolean = false;
	public popupOpen = false;
	public popupOpenPreorder = false;

	public staticMenuLeft: LeftSideMenu[] = StaticNavList.methleftMenu;
	public staticMenuRight: RightSideMenu[] = StaticNavList.methRightMenu;
	public navigationDataFetch: PrimaryNavigation[] = NavigationList.methNavigationList;
	public navShow = true;
	public menuHeight = 0;
	public menuScrolled = false;
	public isCheckoutPageActivated = false;
	private previousUrl: string = '';
	private currentUrl: string = '';

	constructor(
		private elementRefNav: ElementRef,
		private _router: Router,
		private _globalService: GlobalService,
		private _customerProfile: CustomerProfileTransmissionService,
		private _getSetting: SettingsDataService,
		private _getDiscountResponse: DiscountTransmissionService,
		private _autoDiscountCalculation: AutoDiscountCalculationService,
		private _jwt: JWTService,
		private _forexListTransmisson: ForexListTransmissionService,
		private _cartCalculation: CartCalculationService,
		private _priceCalculation: PriceCalculationService,
		private _browser: BrowserAPIService,
		private _localStorage: LocalStorageService,
		private _forex: StoreForexService,
		private _currencyResolver: CurrencyResolverService,
		private _wishlist: WishlistService,
		private _forexTransmission: ForexTransmissionService,
		private _updateProfileCurrency: UpdateForexInProfileService,
		public gtag: GtagAPIService,
		private _bloomsight: BloomsightService
	) {

		this.currentUrl = this._router.url;
		this._router.events.subscribe((event: Event) => {

			if (event instanceof NavigationEnd) {

				this.previousUrl = this.currentUrl;
				this.currentUrl = event.url;

				if (this.currentUrl === '/' + RequestMapperService.NOT_FOUND_URL) {
					this._bloomsight.trackDataEvent(
						'65cba118d0cb5e3e55ce5141',
						{previousUrl: this.previousUrl},
						''
					);
				}

				if (this._browser.isBrowser())
					this._browser.window()?.scrollTo(0, 0);

				this.currentRoute = event.url;

				if (this.hideNavForPages.includes((this.currentRoute.replace('/', '')))) {
					this.navShow = false;
				}

				this.isHomePage = this.currentRoute === '/' || this.currentRoute === '/home';

				if (this._jwt.hasValidJWT()) {
					this.userDiscountGet();
				}
				// this.isCheckoutPageActivated = this.currentRoute == '/checkout';
			}

			if (event instanceof NavigationError) {
				// Hide progress spinner or progress bar

				// Present error to user
				// console.log(event.error);
				if (this.hideNavForPages.includes((this.currentRoute.replace('/', '')))) {
					this.navShow = false;
				}
			}
		});
	}

	public scrollEvent = (event: any): void => {
		if (event.srcElement.scrollingElement) { // for inner scroller scroll
			const n = event.srcElement.scrollingElement.scrollTop;
			// console.log(n, this.menuHeight)
			this.menuScrolled = n > 0;
		}

	}

	public getForex(): void {
		this._forexTransmission.getForexResponse(
			RequestMapperService.FOREX,
			(): void => {
			},
			(): void => {
			},
			(response: Forex): void => {
				this._forex.forexResponse = response;
				this._currencyResolver.selectCurrencyChange.next(null);
			},
			(): void => {
				this._currencyResolver.selectCurrencyChange.next(null);
			},
			(): void => {
			}
		)
	}

	public userProfileApi(): void {
		this._customerProfile.getCustomerInfo(
			RequestMapperService.CUSTOMER_PROFILE,
			() => {
			},
			() => {
			},
			(response: Customer) => {
				this._globalService.sendCustomerInfo(response);
				this._wishlist.setWishlistCSV(response.wishlist);
				let countryName: string = '';
				if (response.defaultCurrency === 'inr') countryName = 'India';
				else if (response.defaultCurrency === 'usd') countryName = 'United States';
				else if (response.defaultCurrency === 'eur') countryName = 'Andorra';
				else if (response.defaultCurrency === 'gbp') countryName = 'United Kingdom';

				if (response.defaultCurrency && !isEmptyString(response.defaultCurrency)) {
					this._currencyResolver.setSelectedCurrency(
						this._currencyResolver.convertStringToApCurrency(response.defaultCurrency)
					)
				} else {
					const locationCurrency = this._currencyResolver.getLocationCurrency();
					this._currencyResolver.setSelectedCurrency(locationCurrency);
					this._updateProfileCurrency.updateProfileApi(locationCurrency.toLowerCase());
				}
				this._localStorage.store('defaultCountry', countryName);
				//this._forex.countryChange.next(countryName);
			},
			() => {
			},
			() => {
			}
		)
	}

	public userDiscountGet(): void {
		this._getDiscountResponse.getDiscount(
			RequestMapperService.GET_DISCOUNT,
			() => {
			},
			() => {
			},
			(response: Discount[]) => {
				// console.log(response);
				this._autoDiscountCalculation.discountList = response;
			},
			() => {
			},
			() => {
			}
		)
	}

	public ngOnInit(): void {

		this.menuHeight = this.elementRefNav.nativeElement.offsetHeight;

		this._browser.window()?.addEventListener('scroll', this.scrollEvent, true);

		this._localStorage.removeItems(['changedCountry']);

		this._currencyResolver.getLocationCurrencyFromAPI(
			(): void => {
				this.getForex();
				if (this._jwt.hasValidJWT()) {
					this.userProfileApi();
				} else {
					this._localStorage.removeItems(['cartData']);
				}
				this.showCookieBanner = this._currencyResolver.countryOfOrigin !== 'India';
			}
		);
		this.getSettings();


		this._globalService.popupOpenClose.subscribe((data: boolean) => {
			this.popupOpen = data;
		});

		this._globalService.popupOpenClosePreOrder.subscribe((data: boolean) => {
			this.popupOpenPreorder = data;
			if (!data) {
				this._cartCalculation.orderType = this._cartCalculation.previouslySelectedOrderType;
				this._priceCalculation.productQuantity = 1;
			}
		});

		this._globalService.isShippingAndPaymentActiveInCheckout.subscribe((isActive: boolean) => {
			this.isCheckoutPageActivated = isActive;
		});
	}

	public getSettings(): void {
		this._getSetting.getSetSetting();
	}

	public ngOnDestroy(): void {
		this._browser.window()?.removeEventListener('scroll', this.scrollEvent, true);
	}
}
