import {Component} from '@angular/core';

@Component({
  selector: 'fb-homepage-instagram-post',
  templateUrl: './homepage-instagram-post.component.html',
  styleUrls: ['./homepage-instagram-post.component.scss']
})
export class HomepageInstagramPostComponent {

}
